
import { Box, Heading,IconButton, Link,  Stack, VStack,  useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { NotificationWithLink } from '../components/notification/NotificationWithLink'
import { useEffect, } from 'react'
import { PhoneIcon } from '@chakra-ui/icons'



import { UNEMPLOYMENT_CONTACT_NOTIFICATION_DESCRIPTION, UNEMPLOYMENT_CONTACT_NOTIFICATION_HEADER, UNEMPLOYMENT_HELMET_DESCRIPTION, UNEMPLOYMENT_HELMET_TITLE, UNEMPLOYMENT_PRICING_TITLE } from '../locales/types'
import { Trans, useTranslation } from 'react-i18next'

import { Helmet } from 'react-helmet'
const transPath = "page.unemployment.section.pricing"
export const UneploymentPage = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    useEffect(() => {
        const timeoutid = setTimeout(() => onOpen(), 60000)
        return () => clearTimeout(timeoutid);
    }, [onOpen])
    return (<Box scrollBehavior="smooth" position={'relative'} m={0} minH={'75vh'} >

        <Helmet>
            <title>{t(`page.unemployment.helmet.${UNEMPLOYMENT_HELMET_TITLE}`)}</title>
            <meta name="description" content={t(`page.unemployment.helmet.${UNEMPLOYMENT_HELMET_DESCRIPTION}`)}></meta>
            <meta property="og:title" content={t(`page.unemployment.helmet.${UNEMPLOYMENT_HELMET_TITLE}`)} />
            <meta property="og:description" content={t(`page.unemployment.helmet.${UNEMPLOYMENT_HELMET_DESCRIPTION}`)} />
            <meta property="og:image" content="https://linaresprocorp.com/assets/images/credit_cards.png" />
            <meta property="og:url" content="https://linaresprocorp.com/unemployment"></meta>
        </Helmet>
        <Box m={0} p={0} w={'full'} h={ 'full' } position={'relative'} >
            <div  style={{
                height: "40vh",
                width: "100vw",
                backgroundImage: 'url("https://res.cloudinary.com/druyvgiba/image/upload/v1729205185/linaresprocorp/workforce/spynawtbro7pf9yuw1a2.jpg")',
                backgroundColor:  useColorModeValue('blackAlpha.600', 'blackAlpha.900'),
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'absolute',
            }} />


        </Box>

        <Box
            backgroundSize={'cover'}
            mt={'30vh'}>

            <Box
                mx="auto"
                px={{
                    base: '0',
                    lg: '12',
                }}
                py={{
                    base: '0',
                    lg: '12',
                }}
            >

                <Stack
                    direction={{
                        base: 'column-reverse',
                        lg: 'row',
                    }}
                    justifyContent={'center'}
                    alignItems={{ base: 'center', sm: 'center' }}
                    spacing={{ base: '6', md: '12' }}
                    position={'relative'}
                >

                    <Box  w={'full'} height={'full'}  background={useColorModeValue('blackAlpha.600', 'blackAlpha.800')}
                        display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <VStack spacing={10} top={'8%'} zIndex={3} m={5}>
                            <Heading as={'h1'} color={'whiteAlpha.900'} fontSize={{ base: '2xl', md: '4xl', lg: '6xl' }} fontWeight={'bold'} >
                                <Trans i18nKey={`${transPath}.${UNEMPLOYMENT_PRICING_TITLE}`} />
                            </Heading>
                        </VStack>

                    </Box>
                    

                </Stack>
            </Box>


        </Box>

        <Box pos={'relative'}>
            <Box display={isOpen ? 'flow' : 'none'} position={'fixed'} top={'8em'} right={'5px'} zIndex={999}>
                <NotificationWithLink onClose={onClose}
                    iconButton={<IconButton as={'a'} icon={<PhoneIcon />} href='tel:+17869726285' />}
                    title={<Trans i18nKey={`page.credit_repair.contact.${UNEMPLOYMENT_CONTACT_NOTIFICATION_HEADER}`} />}
                    message={<Link href='tel:+17869726285' textStyle="md" color="fg.muted">
                        <Trans i18nKey={`page.credit_repair.contact.${UNEMPLOYMENT_CONTACT_NOTIFICATION_DESCRIPTION}`} />
                    </Link>}
                />
            </Box>
        </Box>
        
     
        {/*<CreditRepairFAQ />*/}
    </Box>)
}



export default UneploymentPage;