

//HOME PAGE
export const HOME_HELMET_TITLE = "HOME_HELMET_TITLE";
export const HOME_HELMET_DESCRIPTION = "HOME_HELMET_DESCRIPTION";


export const WELCOME_BUSINESS_HEADER = "WELCOME_BUSINESS_HEADER";
export const WELCOME_BUSINESS_DESCRIPTION = "WELCOME_BUSINESS_DESCRIPTION";
export const DASHBOARD_BUTTON = "DASHBOARD_BUTTON";
export const TEMPLATES_BUTTON = "TEMPLATES_BUTTON";

//HOME PAGE SERVICES 
export const HOME_SERVICES_HEADER_TITLE = "HOME_SERVICES_HEADER_TITLE";


//CONTACT PAGE
export const CONTACT_HELMET_TITLE = "CONTACT_HELMET_TITLE";
export const CONTACT_HELMET_DESCRIPTION = "CONTACT_HELMET_DESCRIPTION";



export const FEATURES_SECTION_HEADER = "FEATURES_SECTION_HEADER"
export const ONLINE_BOOKING_HEADER = "ONLINE_BOOKING_HEADER";
export const ONLINE_BOOKING_DESCRIPTION = "ONLINE_BOOKING_DESCRIPTION"
export const BUSINESS_PROMOTION_HEADER = "BUSINESS_PROMOTION_HEADER"
export const BUSINESS_PROMOTION_DESCRIPTION = "BUSINESS_PROMOTION_DESCRIPTION"
export const ADMIN_DASHBOARD_HEADER = "ADMIN_DASHBOARD_HEADER"
export const ADMIN_DASHBOARD_DESCRIPTION = "ADMIN_DASHBOARD_DESCRIPTION"
export const SMS_EMAIL_NOTIFICATIONS_HEADER = " SMS_EMAIL_NOTIFICATIONS_HEADER"
export const SMS_EMAIL_NOTIFICATIONS_DESCRIPTION = "SMS_EMAIL_NOTIFICATIONS_DESCRIPTION"
export const PAYMENT_HEADER = "PAYMENT_HEADER"
export const PAYMENT_DESCRIPTION = "PAYMENT_DESCRIPTION"
export const WEBSITE_TEMPLATES_HEADER = "WEBSITE_TEMPLATES_HEADER"
export const WEBSITE_TEMPLATES_DESCRIPTION = "WEBSITE_TEMPLATES_DESCRIPTION"
export const ACCESSIBLE_HEADER = "ACCESSIBLE_HEADER"
export const ACCESSIBLE_DESCRIPTION = "ACCESSIBLE_DESCRIPTION"


//LOGIN PAGE
export const LOGIN_FORM_TITLE = "LOGIN_FORM_TITLE"
export const LOGIN_FORM_NOT_REGISTERED_QUESTION = "LOGIN_FORM_NOT_REGISTERED_QUESTION"
export const LOGIN_FORM_NOT_REGISTERED_OPTION = "LOGIN_FORM_NOT_REGISTERED_OPTION"
export const LOGIN_FORM_ISSUES_QUESTION = "LOGIN_FORM_ISSUES_QUESTION"
export const LOGIN_FORM_ISSUES_OPTION = "LOGIN_FORM_ISSUES_OPTION"
export const LOGIN_FORM_REMEBER_ME_LABEL = "LOGIN_FORM_REMEBER_ME_LABEL"

export const LOGIN_FORM_LABEL_EMAIL = "LOGIN_FORM_LABEL_EMAIL"
export const LOGIN_FORM_PLACEHOLDER_EMAIL = "LOGIN_FORM_PLACEHOLDER_EMAIL"

export const LOGIN_FORM_LABEL_PASSWORD = "LOGIN_FORM_LABEL_PASSWORD"
export const LOGIN_FORM_PLACEHOLDER_PASSWORD = "LOGIN_FORM_PLACEHOLDER_PASSWORD"
export const LOGIN_FORM_SUBMIT_BUTTON = "LOGIN_FORM_SUBMIT_BUTTON"
export const LOGIN_FORM_GOOGLE_BUTTON = "LOGIN_FORM_GOOGLE_BUTTON"



//MAIN NAVBAR
export const NAVBAR_LINK_HOME = "NAVBAR_LINK_HOME";
export const NAVBAR_LINK_IMMIGRATION = "NAVBAR_LINK_IMMIGRATION";
export const NAVBAR_LINK_SERVICES = "NAVBAR_LINK_SERVICES";
export const NAVBAR_LINK_PASSPORT = "NAVBAR_LINK_PASSPORT";
export const NAVBAR_LINK_TAXES = "NAVBAR_LINK_TAXES";
export const NAVBAR_LINK_TRAVEL = "NAVBAR_LINK_TRAVEL";
export const NAVBAR_LINK_CREDIT_REPAIR = "NAVBAR_LINK_CREDIT_REPAIR";
export const NAVBAR_LINK_LOGIN = "NAVBAR_LINK_LOGIN";
export const NAVBAR_LINK_SIGNUP = "NAVBAR_LINK_SIGNUP";
export const NAVBAR_LINK_DASHBOARD = "NAVBAR_LINK_DASHBOARD";
export const NAVBAR_LINK_SUPPORT = "NAVBAR_LINK_SUPPORT";
export const NAVBAR_LINK_CONTACT_US = "NAVBAR_LINK_CONTACT_US";
export const NAVBAR_LINK_ABOUT_US = "NAVBAR_LINK_ABOUT_US";

//MAIN FOOTER
export const MF_PAGE_LINK_TITLE_LINARES = "MF_PAGE_LINK_TITLE_LINARES";
export const MF_PAGE_LINK_TITLE_SERVICES="MF_PAGE_LINK_TITLE_SERVICES";
export const MF_PAGE_LINK_ABOUT_US = "MF_PAGE_LINK_ABOUT_US";
export const MF_PAGE_LINK_TERMS_OF_USE = "MF_PAGE_LINK_TERMS_OF_USE ";
export const MF_PAGE_LINK_CONTACT = "MF_PAGE_LINK_CONTACT";
export const MF_PAGE_LINK_PRIVACY_POLICY = "MF_PAGE_LINK_PRIVACY_POLICY";
export const MF_PAGE_LINK_DISCLAIMER = "MF_PAGE_LINK_DISCLAIMER";
export const MF_PAGE_LINK_COOKIES_POLICY = "MF_PAGE_LINK_COOKIES_POLICY";



//UNEMPLOYMENT PAGE
export const UNEMPLOYMENT_HELMET_TITLE = "UNEMPLOYMENT_HELMET_TITLE"
export const UNEMPLOYMENT_HELMET_DESCRIPTION = "UNEMPLOYMENT_HELMET_DESCRIPTION"



//CREDIT REAPAIR PAGE
export const UNEMPLOYMENT_PRICING_PLAN_TITLE = "UNEMPLOYMENT_PRICING_PLAN_TITLE"
export const UNEMPLOYMENT_PRICING_PLAN_SUBTITLE = "UNEMPLOYMENT_PRICING_PLAN_SUBTITLE"
export const UNEMPLOYMENT_HEADER_TITLE = "UNEMPLOYMENT_HEADER_TITLE"
export const UNEMPLOYMENT_HEADER_DESCRIPTION = "UNEMPLOYMENT_HEADER_DESCRIPTION"
export const UNEMPLOYMENT_PRICING_TITLE = "UNEMPLOYMENT_PRICING_TITLE"
export const UNEMPLOYMENT_PRICING_DESCRIPTION = "UNEMPLOYMENT_PRICING_DESCRIPTION"
export const UNEMPLOYMENT_PRICING_TRIAL_DESCRIPTION = "UNEMPLOYMENT_PRICING_TRIAL_DESCRIPTION"
export const UNEMPLOYMENT_PRICING_TESTIMONIAL = "UNEMPLOYMENT_PRICING_TESTIMONIAL"
export const UNEMPLOYMENT_PRICING_PRO_LATE_PAYMENTS = "UNEMPLOYMENT_PRICING_PRO_LATE_PAYMENTS"
export const UNEMPLOYMENT_PRICING_PRO_HARD_INQUIRIES = "UNEMPLOYMENT_PRICING_PRO_HARD_INQUIRIES"
export const UNEMPLOYMENT_PRICING_PRO_PERSONAL_INFORMATION = "UNEMPLOYMENT_PRICING_PRO_PERSONAL_INFORMATION"
export const UNEMPLOYMENT_PRICING_PRO_COLLECTION_CHARGES = "UNEMPLOYMENT_PRICING_PRO_COLLECTION_CHARGES"
export const UNEMPLOYMENT_PRICING_PRO_CHARGE_OFFS = "UNEMPLOYMENT_PRICING_PRO_CHARGE_OFFS"
export const UNEMPLOYMENT_PRICING_PRO_MEDICAL_BILLS = "UNEMPLOYMENT_PRICING_PRO_MEDICAL_BILLS"
export const UNEMPLOYMENT_PRICING_PRO_STUDENT_LOANS = "UNEMPLOYMENT_PRICING_PRO_STUDENT_LOANS"
export const UNEMPLOYMENT_PRICING_PRO_REPOSSESSIONS = "UNEMPLOYMENT_PRICING_PRO_REPOSSESSIONS"
export const UNEMPLOYMENT_PRICING_PRO_BANKRUPTICIES = "UNEMPLOYMENT_PRICING_PRO_BANKRUPTICIES"
export const UNEMPLOYMENT_PRICING_PRO_PUBLIC_RECORDS = "UNEMPLOYMENT_PRICING_PRO_PUBLIC_RECORDS"
export const UNEMPLOYMENT_PRICING_FREE_CONSULTING = "UNEMPLOYMENT_PRICING_FREE_CONSULTING"
export const UNEMPLOYMENT_PRICING_FREE_CONSULTING_DESCRIPTION = "UNEMPLOYMENT_PRICING_FREE_CONSULTING_DESCRIPTION"
export const UNEMPLOYMENT_PRICING_PRO_BUTTON_SUBSCRIBE = "UNEMPLOYMENT_PRICING_PRO_BUTTON_SUBSCRIBE"
export const UNEMPLOYMENT_PRICING_FREE_CONSULTING_BUTTON_CONTACT = "UNEMPLOYMENT_PRICING_FREE_CONSULTING_BUTTON_CONTACT"


//CREDIT REPAIR MANAGE SUBSCRIPTION
export const UNEMPLOYMENT_MANAGE_SUBSCRIPTION_TITLE = "UNEMPLOYMENT_MANAGE_SUBSCRIPTION_TITLE"
export const UNEMPLOYMENT_MANAGE_SUBSCRIPTION_INSTRUCTION = "UNEMPLOYMENT_MANAGE_SUBSCRIPTION_DESCRIPTION"
export const UNEMPLOYMENT_MANAGE_SUBSCRIPTION_BUTTON = "UNEMPLOYMENT_MANAGE_SUBSCRIPTION_BUTTON"
//CREDIT REAPAIR PROCESS
export const UNEMPLOYMENT_PROCESS_HEADER = "UNEMPLOYMENT_PROCESS_HEADER"
export const UNEMPLOYMENT_PROCESS_SIGUNP_HEADER = "UNEMPLOYMENT_PROCESS_SIGUNP_HEADER"
export const UNEMPLOYMENT_PROCESS_SIGUNP_DESCRIPTION = "UNEMPLOYMENT_PROCESS_SIGUNP_DESCRIPTION"
export const UNEMPLOYMENT_PROCESS_COACH_HEADER = "UNEMPLOYMENT_PROCESS_COACH_HEADER"
export const UNEMPLOYMENT_PROCESS_COACH_DESCRIPTION = "UNEMPLOYMENT_PROCESS_COACH_DESCRIPTION"
export const UNEMPLOYMENT_PROCESS_ASSESTMENT_HEADER = "UNEMPLOYMENT_PROCESS_ASSESTMENT_HEADER"
export const UNEMPLOYMENT_PROCESS_ASSESTMENT_DESCRIPTION = "UNEMPLOYMENT_PROCESS_ASSESTMENT_DESCRIPTION"
export const UNEMPLOYMENT_PROCESS_DISPUTES_HEADER = "UNEMPLOYMENT_PROCESS_DISPUTES_HEADER"
export const UNEMPLOYMENT_PROCESS_DISPUTES_DESCRIPTION = "UNEMPLOYMENT_PROCESS_DISPUTES_DESCRIPTION"

//CREDIT REAPAIR CONTACT NOTIFICATION
export const UNEMPLOYMENT_CONTACT_NOTIFICATION_HEADER = "UNEMPLOYMENT_CONTACT_NOTIFICATION_HEADER"
export const UNEMPLOYMENT_CONTACT_NOTIFICATION_DESCRIPTION = "UNEMPLOYMENT_CONTACT_NOTIFICATION_DESCRIPTION"

//CREDIT REAPAIR FAQ
export const UNEMPLOYMENT_FAQ_TITLE = "UNEMPLOYMENT_FAQ_TITLE"
export const UNEMPLOYMENT_FAQ_SUBTITLE = "UNEMPLOYMENT_FAQ_SUBTITLE"
export const UNEMPLOYMENT_FAQ_DESCRIPTION = "UNEMPLOYMENT_FAQ_DESCRIPTION"
export const UNEMPLOYMENT_FAQ_HOW_WORK_QUESTION = "UNEMPLOYMENT_FAQ_HOW_WORK_QUESTION"
export const UNEMPLOYMENT_FAQ_HOW_WORK_ANSWER = "UNEMPLOYMENT_FAQ_HOW_WORK_ANSWER"
export const UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_QUESTION = "UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_QUESTION"
export const UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_ANSWER = "UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_ANSWER"
export const UNEMPLOYMENT_FAQ_CAN_I_DO_IT_QUESTION = "UNEMPLOYMENT_FAQ_CAN_I_DO_IT_QUESTION"
export const UNEMPLOYMENT_FAQ_CAN_I_DO_IT_ANSWER = "UNEMPLOYMENT_FAQ_CAN_I_DO_IT_ANSWER"
export const UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION = "UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION"
export const UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER = "UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER"
export const UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION = "UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION"
export const UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER = "UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER"
export const UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION = "UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION"
export const UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER = "UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER"
export const UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION = "UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION"
export const UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER = "UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER"
























// CREDIT REAPAIR PAGE HELMET
export const CR_HELMET_TITLE = "CR_HELMET_TITLE"
export const CR_HELMET_DESCRIPTION = "CR_HELMET_DESCRIPTION"







//CREDIT REAPAIR PAGE
export const CR_PRICING_PLAN_TITLE = "CR_PRICING_PLAN_TITLE"
export const CR_PRICING_PLAN_SUBTITLE = "CR_PRICING_PLAN_SUBTITLE"
export const CR_HEADER_TITLE = "CR_HEADER_TITLE"
export const CR_HEADER_DESCRIPTION = "CR_HEADER_DESCRIPTION"
export const CR_PRICING_TITLE = "CR_PRICING_TITLE"
export const CR_PRICING_DESCRIPTION = "CR_PRICING_DESCRIPTION"
export const CR_PRICING_TRIAL_DESCRIPTION = "CR_PRICING_TRIAL_DESCRIPTION"
export const CR_PRICING_TESTIMONIAL = "CR_PRICING_TESTIMONIAL"
export const CR_PRICING_PRO_LATE_PAYMENTS = "CR_PRICING_PRO_LATE_PAYMENTS"
export const CR_PRICING_PRO_HARD_INQUIRIES = "CR_PRICING_PRO_HARD_INQUIRIES"
export const CR_PRICING_PRO_PERSONAL_INFORMATION = "CR_PRICING_PRO_PERSONAL_INFORMATION"
export const CR_PRICING_PRO_COLLECTION_CHARGES = "CR_PRICING_PRO_COLLECTION_CHARGES"
export const CR_PRICING_PRO_CHARGE_OFFS = "CR_PRICING_PRO_CHARGE_OFFS"
export const CR_PRICING_PRO_MEDICAL_BILLS = "CR_PRICING_PRO_MEDICAL_BILLS"
export const CR_PRICING_PRO_STUDENT_LOANS = "CR_PRICING_PRO_STUDENT_LOANS"
export const CR_PRICING_PRO_REPOSSESSIONS = "CR_PRICING_PRO_REPOSSESSIONS"
export const CR_PRICING_PRO_BANKRUPTICIES = "CR_PRICING_PRO_BANKRUPTICIES"
export const CR_PRICING_PRO_PUBLIC_RECORDS = "CR_PRICING_PRO_PUBLIC_RECORDS"
export const CR_PRICING_FREE_CONSULTING = "CR_PRICING_FREE_CONSULTING"
export const CR_PRICING_FREE_CONSULTING_DESCRIPTION = "CR_PRICING_FREE_CONSULTING_DESCRIPTION"
export const CR_PRICING_PRO_BUTTON_SUBSCRIBE = "CR_PRICING_PRO_BUTTON_SUBSCRIBE"
export const CR_PRICING_FREE_CONSULTING_BUTTON_CONTACT = "CR_PRICING_FREE_CONSULTING_BUTTON_CONTACT"


//CREDIT REPAIR MANAGE SUBSCRIPTION
export const CR_MANAGE_SUBSCRIPTION_TITLE = "CR_MANAGE_SUBSCRIPTION_TITLE"
export const CR_MANAGE_SUBSCRIPTION_INSTRUCTION = "CR_MANAGE_SUBSCRIPTION_DESCRIPTION"
export const CR_MANAGE_SUBSCRIPTION_BUTTON = "CR_MANAGE_SUBSCRIPTION_BUTTON"
//CREDIT REAPAIR PROCESS
export const CR_PROCESS_HEADER = "CR_PROCESS_HEADER"
export const CR_PROCESS_SIGUNP_HEADER = "CR_PROCESS_SIGUNP_HEADER"
export const CR_PROCESS_SIGUNP_DESCRIPTION = "CR_PROCESS_SIGUNP_DESCRIPTION"
export const CR_PROCESS_COACH_HEADER = "CR_PROCESS_COACH_HEADER"
export const CR_PROCESS_COACH_DESCRIPTION = "CR_PROCESS_COACH_DESCRIPTION"
export const CR_PROCESS_ASSESTMENT_HEADER = "CR_PROCESS_ASSESTMENT_HEADER"
export const CR_PROCESS_ASSESTMENT_DESCRIPTION = "CR_PROCESS_ASSESTMENT_DESCRIPTION"
export const CR_PROCESS_DISPUTES_HEADER = "CR_PROCESS_DISPUTES_HEADER"
export const CR_PROCESS_DISPUTES_DESCRIPTION = "CR_PROCESS_DISPUTES_DESCRIPTION"

//CREDIT REAPAIR CONTACT NOTIFICATION
export const CR_CONTACT_NOTIFICATION_HEADER = "CR_CONTACT_NOTIFICATION_HEADER"
export const CR_CONTACT_NOTIFICATION_DESCRIPTION = "CR_CONTACT_NOTIFICATION_DESCRIPTION"

//CREDIT REAPAIR FREE SCORE by MyFreeScoreNow
export const CR_FREE_SCORE_TITLE = "CR_FREE_SCORE_TITLE"
export const CR_FREE_SCORE_DESCRIPTION = "CR_FREE_SCORE_DESCRIPTION"
export const CR_FREE_SCORE_BUTTON = "CR_FREE_SCORE_BUTTON"
export const CR_FREE_SCORE_DISCLAIMER = "CR_FREE_SCORE_DISCLAIMER"
export const CR_FREE_SCORE_REDIRECT_NOTIFICATION = "CR_FREE_SCORE_REDIRECT_NOTIFICATION"
export const CR_FREE_SCORE_REDIRECT_ALERT_DESCRIPTION = "CR_FREE_SCORE_REDIRECT_ALERT_DESCRIPTION"
export const CR_FREE_SCORE_REDIRECT_ALERT_BUTTON = "CR_FREE_SCORE_REDIRECT_ALERT_BUTTON"
export const CR_FREE_SCORE_REDIRECT_ALERT_CANCEL_BUTTON = "CR_FREE_SCORE_REDIRECT_ALERT_CANCEL_BUTTON"





//CREDIT REAPAIR FAQ
export const CR_FAQ_TITLE = "CR_FAQ_TITLE"
export const CR_FAQ_SUBTITLE = "CR_FAQ_SUBTITLE"
export const CR_FAQ_DESCRIPTION = "CR_FAQ_DESCRIPTION"
export const CR_FAQ_HOW_WORK_QUESTION = "CR_FAQ_HOW_WORK_QUESTION"
export const CR_FAQ_HOW_WORK_ANSWER = "CR_FAQ_HOW_WORK_ANSWER"
export const CR_FAQ_HOW_LONG_TAKE_QUESTION = "CR_FAQ_HOW_LONG_TAKE_QUESTION"
export const CR_FAQ_HOW_LONG_TAKE_ANSWER = "CR_FAQ_HOW_LONG_TAKE_ANSWER"
export const CR_FAQ_CAN_I_DO_IT_QUESTION = "CR_FAQ_CAN_I_DO_IT_QUESTION"
export const CR_FAQ_CAN_I_DO_IT_ANSWER = "CR_FAQ_CAN_I_DO_IT_ANSWER"
export const CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION = "CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION"
export const CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER = "CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER"
export const CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION = "CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION"
export const CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER = "CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER"
export const CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION = "CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION"
export const CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER = "CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER"
export const CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION = "CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION"
export const CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER = "CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER"


//ABOUT US PAGE
export const ABOUT_US_HELMET_TITLE = "ABOUT_US_HELMET_TITLE"
export const ABOUT_US_HELMET_DESCRIPTION = "ABOUT_US_HELMET_DESCRIPTION"
export const ABOUT_US_HEADER_TITLE = "ABOUT_US_HEADER_TITLE"
export const ABOUT_US_INTRO_TITLE = "ABOUT_US_INTRO_TITLE"
export const ABOUT_US_INTRO_SUBTITLE = "ABOUT_US_INTRO_SUBTITLE"
export const ABOUT_US_INTRO_DESCRIPTION = "ABOUT_US_INTRO_DESCRIPTION"
export const ABOUT_US_FIRST_PARAGRAPH = "ABOUT_US_FIRST_PARAGRAPH"
export const ABOUT_US_SECOND_PARAGRAPH = "ABOUT_US_SECOND_PARAGRAPH"
export const ABOUT_US_THIRD_PARAGRAPH = "ABOUT_US_THIRD_PARAGRAPH"