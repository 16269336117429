import { CONTACT_HELMET_DESCRIPTION, CONTACT_HELMET_TITLE } from "../types";



const en_contact_page = {//ABOUT US PAGE
    helmet: {
        [CONTACT_HELMET_TITLE]: "Contact Us | Linares Professional Family Services Corp",
        [CONTACT_HELMET_DESCRIPTION]: `Contact us today to learn more about our services and how we can help you and your family. We are here to answer any questions you may have and provide the support you need.`,
    },

}

export default en_contact_page;

