'use client'

import {
    Box,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Flex,
    useColorModeValue,
    Image,


} from '@chakra-ui/react'
import logoImage from '@assets/logo 3d 512.png'
import { useState } from 'react'
import { EmailSubscriptionForm } from '@components/contact/EmailSubcriptionForm'
import { MF_PAGE_LINK_ABOUT_US, MF_PAGE_LINK_CONTACT, MF_PAGE_LINK_COOKIES_POLICY, MF_PAGE_LINK_DISCLAIMER, MF_PAGE_LINK_PRIVACY_POLICY, MF_PAGE_LINK_TERMS_OF_USE, MF_PAGE_LINK_TITLE_LINARES, MF_PAGE_LINK_TITLE_SERVICES } from 'src/locales/types'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const transPath = 'footer'

const Logo = (props) => {
    return (
        <Image w={150} h={150} src={logoImage} alt='Lianres Pro Corp Logo' {...props} />
    )
}

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

export default function MainFooter() {
    const navigate=useNavigate();
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
            bgSize={'contain'}
            bgRepeat={'no-repeat'}
        >

            <Container as={Stack} maxW={'full'} py={5}>
                <SimpleGrid pl={['0', '30%', '35%']} columns={{ base: 1, md: 3, xl: 4 }} spacing={8}>
                    <Stack align={'flex-start'} >
                        <Box w={'100%'} p={2}>
                            <ListHeader>
                                <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_TITLE_LINARES}`} />
                            </ListHeader>
                        </Box>
                        <Box as="a" href={'/about'} onClick={(evt)=>{
                         evt.preventDefault();
                         evt.stopPropagation();
                         navigate("/about");
                        }} ml={4}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_ABOUT_US}`} />
                        </Box>

                        <Box as="a" href={'#'} ml={4}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_CONTACT}`} />
                        </Box>

                    </Stack>
                    <Stack >
                        <Box w={'100%'} p={2} >
                            <ListHeader> <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_TITLE_SERVICES}`} /></ListHeader>
                        </Box>
                        <Box ml={4} as="a" >
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_PRIVACY_POLICY}`} />
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/disclaimer.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_DISCLAIMER}`} />
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/termsandconditions.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_TERMS_OF_USE}`} />
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/cookiepolicy.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_COOKIES_POLICY}`} />
                        </Box>

                    </Stack>
                    <Stack >
                        <Box w={'100%'} p={2} >
                            <ListHeader>Legal</ListHeader>
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/privacypolicy.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_PRIVACY_POLICY}`} />
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/disclaimer.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_DISCLAIMER}`} />
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/termsandconditions.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_TERMS_OF_USE}`} />
                        </Box>
                        <Box ml={4} as="a" target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/legal/cookiepolicy.html`}>
                            <Trans i18nKey={`${transPath}.${MF_PAGE_LINK_COOKIES_POLICY}`} />
                        </Box>

                    </Stack>
                   
                    <Stack  >
                        <Box w={'100%'} p={2} >
                            <ListHeader>Síguenos</ListHeader>
                        </Box>
                        <Box ml={4} as="a" href={'#'}>
                            Facebook
                        </Box>
                        <Box ml={4} as="a" href={'https://www.instagram.com/linaresprocorp/profilecard/?igsh=MXYwMnB6aW90OTd4aw=='}>
                         Instagram
                        </Box>
                        <Box>

                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>

            <Box py={10}>

                <Flex
                    align={'center'}
                    _before={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        mr: 8,
                    }}
                    _after={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: useColorModeValue('gray.200', 'gray.700'),
                        flexGrow: 1,
                        ml: 8,
                    }}>
                    <Logo />
                </Flex>
                <Text pt={6} fontSize={'sm'} textAlign={'center'}>
                    © 2024 Linares Professional Family Service Corp. All rights reserved
                </Text>
            </Box>

        </Box>

    )
}